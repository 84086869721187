<template>
  <dashboard-layout>
    <template #content>
      <!-- page header component -->
      <page-header
        name="documents"
        icon="folder"
      >
        <template #actions>
          <div class="level-item">
            <search-bar />
          </div>

          <div class="level-item">
            <b-dropdown aria-role="list">
              <b-button
                slot="trigger"
                type="is-info"
                icon-left="plus"
                icon-right="chevron-down"
              >
                create
              </b-button>
              <template v-if="hasPermissions('create_accounts')">
                <b-dropdown-item
                  aria-role="listitem"
                  class="has-text-grey"
                  @click="$router.push('/accounts/add')"
                >
                  Account
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <template v-if="hasPermissions('create_contacts')">
                <b-dropdown-item
                  aria-role="listitem"
                  class="has-text-grey"
                  @click="$router.push('/contacts/add')"
                >
                  Contact
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <template v-if="hasPermissions('create_leads')">
                <b-dropdown-item
                  aria-role="listitem"
                  class="has-text-grey"
                  @click="$router.push('/leads/add')"
                >
                  Lead
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <template v-if="hasPermissions('create_leads')">
                <b-dropdown-item
                  aria-role="listitem"
                  class="has-text-grey"
                  @click="$router.push('/opportunities/add')"
                >
                  Opportunity
                </b-dropdown-item>
                <hr class="dropdown-divider">
              </template>

              <b-dropdown-item
                v-if="hasPermissions('create_activities')"
                has-link
                aria-role="menuitem"
                class="has-text-grey"
              >
                <router-link to="/activities/add">
                  Activity
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </page-header>

      <!-- documents table component -->
      <section class="section">
        <div class="container">
          <b-breadcrumb size="is-small">
            <b-breadcrumb-item
              tag="router-link"
              to="/profile"
            >
              Home
            </b-breadcrumb-item>
            <b-breadcrumb-item
              tag="router-link"
              to="/documents"
              active
            >
              Documents
            </b-breadcrumb-item>
          </b-breadcrumb>

          <documents-table
            @edit-document="editDocument"
            @share-document="shareDocument"
          />
        </div>
      </section>

      <!-- share link modal component -->
      <b-modal
        v-model="isShareLinkModalActive"
        scroll="keep"
        :width="400"
      >
        <share-link-modal
          @share-document="shareLink"
          @close="isShareLinkModalActive = false"
        />
      </b-modal>
    </template>
  </dashboard-layout>
</template>

<script>
import { PageHeader } from '@/components/Shared';
import { SearchBar } from '@/components/Search';

import DocumentsMixin from '@/mixins/DocumentsMixin';

export default {

  name: 'Documents',

  components: {
    DashboardLayout: () => import('../layouts/DashboardLayout.vue'),
    PageHeader,
    SearchBar,
  },

  mixins: [DocumentsMixin],

};
</script>

<style>

</style>
